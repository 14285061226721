.card-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 220px;
  }
  
  .carddd {
    margin: 10px;
    margin-left: 116.5px;
    margin-top: 35px;
 
  }
  
  @media (max-width: 450px) {
    .card-container {
      flex-direction: column;
      margin-top: 190px;
    }

    .carddd {
        margin: 10px;
        margin-top: 50px;
        margin-left: 30px;
      }
  }
  
  @media (max-width: 400px) {
    .card-container {
      flex-direction: column;
      margin-top: 190px;
    }

    .carddd {
        margin: 10px;
        margin-top: 50px;
        margin-left: 15px;
      }
  }
    