

.hero {
    position: relative;
    width: 100vw; /* Full width of the viewport */
    height: 110vh; /* Full height of the viewport */
    overflow: hidden; /* Hide any overflow content */
  }
  
  .hero-about
  {
      width: 100%;
      height: 50vh;
      position: relative;
  }
  
  video
  {
      width: 100vw; /* Full width of the viewport */
      height: 50vw;
      object-fit:cover
  }
  


  .hero-text
  {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center; /* Center the text horizontally */
      line-height:3; 
  }
  
  .hero-text h1
  {
    
      font-size: 3rem;
      font-weight: 800;
      background: #fff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  .hero-text p
  {
      font-size: 1.3rem;
      color: #fff;
      padding: 0.5rem 0 0.2rem 0;
      font-weight: 800;
      
  }
  
  .hero-text p::after
  {
    content: "";
    height: 5px;
    background-color: #fff;
    display: block;
    width: 430px;
    margin-left: 550px;
  }


  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 630px;
  margin-top: 500px;
  }


  
  .buttom
  {
    margin-top: 1200px;
  }
  
  .hide-button
  {
      display: none;
  }

  @media screen and (max-width:1400px)
  {
    .hero-text p::after
    {
      content: "";
      height: 5px;
      background-color: #fff;
      display: block;
      width: 430px;
      margin-left: 430px;
    }
  }

  @media screen and (max-width:1200px)
  {
    .hero-text p::after
    {
      content: "";
      height: 5px;
      background-color: #fff;
      display: block;
      width: 430px;
      margin-left: 330px;
    }
  }


  @media screen and (max-width:1000px)
  {
      .hero {
          
          width: 100%; /* Full width of the viewport */
          height: 110vb; /* Full height of the viewport */
         
        }
        
      .hero-text h1
  {
      padding: 10px 20px;
  }
  
  .hero-text p
  {
      font-size: 1.1rem;
      color: #fff;
      padding: 0rem 0 2rem 0;
  }
  

    .hero-text p::after
  {
    content: "";
    height: 5px;
    background-color: #fff;
    display: block;
    width: 430px;
    margin-left: 275px;
  }

  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 315px;
  margin-top: 500px;
  }
  
  }

  @media screen and (max-width:850px)
  {
      .hero {
          
          width: 100%; /* Full width of the viewport */
          height: 110vb; /* Full height of the viewport */
         
        }
        
      .hero-text h1
  {
      padding: 10px 20px;
  }
  
  .hero-text p
  {
      font-size: 1.1rem;
      color: #fff;
      padding: 0rem 0 2rem 0;
  }
  

    .hero-text p::after
  {
    content: "";
    height: 5px;
    background-color: #fff;
    display: block;
    width: 430px;
    margin-left: 195px;
  }

  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 242px;
  margin-top: 500px;
  }
  
  }

  @media screen and (max-width:750px)
  {
      .hero {
          
          width: 100%; /* Full width of the viewport */
          height: 110vb; /* Full height of the viewport */
         
        }
        
      .hero-text h1
  {
      padding: 10px 20px;
  }
  
  .hero-text p
  {
      font-size: 1.1rem;
      color: #fff;
      padding: 0rem 0 2rem 0;
  }
  

    .hero-text p::after
  {
    content: "";
    height: 5px;
    background-color: #fff;
    display: block;
    width: 430px;
    margin-left: 100px;
  }

  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 242px;
  margin-top: 500px;
  }
  
  }

  @media screen and (max-width:700px)
  {
    
  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 208px;
  margin-top: 500px;
  }
  
  }
  
  @media screen and (max-width:650px)
  {
      .hero {
          
          width: 100%; /* Full width of the viewport */
          height: 110vb; /* Full height of the viewport */
         
        }
        
      .hero-text h1
  {
      padding: 10px 20px;
      font-size: 2rem;
  }
  
  .hero-text p
  {
      font-size: 1.1rem;
      color: #fff;
      padding: 0rem 0 2rem 0;
  }
  

    .hero-text p::after
  {
    content: "";
    height: 5px;
    background-color: #fff;
    display: block;
    width: 430px;
    margin-left: 100px;
  }

  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 180px;
  margin-top: 500px;
  }
  
  }

   @media screen and (max-width:600px)
  {     
    img
    {
        width: 100%; /* Full width of the viewport */
        height: 100vh;
        
        
    }

      .hero {
          
         width: 100%; /* Full width of the viewport */
        height: 110vb; /* Full height of the viewport */
         
        }
        
      .hero-text h1
  {
      padding: 10px 20px;
      font-size: 30px;
  }
  
  .hero-text p
  {
      font-size: 1.1rem;
      color: #fff;
      padding: 0rem 0 2rem 0;
  }
  

    .hero-text p::after
  {
    content: "";
    height: 5px;
    background-color: #fff;
    display: block;
    width: 600px;
    margin-left: -1px;
  }

  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 165px;
  margin-top: 500px;
  }

  
  
  }  

  @media screen and (max-width:550px)
  {
    
  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 117px;
  margin-top: 500px;
  }

  }

  @media screen and (max-width:500px)
  {
    
  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 106px;
  margin-top: 500px;
  }

  }

  @media screen and (max-width:450px)
  {
    
  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 95px;
  margin-top: 500px;
  }

  }

  @media screen and (max-width:410px)
  {     
    img
    {
        width: 100%; /* Full width of the viewport */
        height: 50vh;
        
        
    }

      .hero {
          
         width: 100%; /* Full width of the viewport */
        height: 110vb; /* Full height of the viewport */
         
        }
        
      .hero-text h1
  {
        font-size: 25px;
        
  }
  
  .hero-text p
  {
      font-size: 1.1rem;
      color: #fff;
      padding: 0rem 0 2rem 0;
  }
  

    .hero-text p::after
  {
    content: "";
    height: 5px;
    background-color: #fff;
    display: block;
    width: 430px;
    margin-left: -1px;
  }

  .show
  {
     
  text-decoration: none;
  background-color: #063970;
  padding: 1rem 2rem ;
  border-radius: 6px;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-left: 73px;
  margin-top: 500px;
  }

  
  
  }

 


  @media screen and (max-width:380px)
  {
    .hero-text h1
    {
        padding: 10px 20px;
        font-size: 25px;
    }

    .show
    {
       
    text-decoration: none;
    background-color: #063970;
    padding: 1rem 2rem ;
    border-radius: 6px;
    font-size: 2.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
    margin-left: 58px;
    margin-top: 500px;
    }
  
  }

  @media screen and (max-width:350px)
  {     
  

    img
    {
        width: 100%; /* Full width of the viewport */
        height: 100vh;
        
        
    }

      .hero {
          
         width: 100%; /* Full width of the viewport */
        height: 110vb; /* Full height of the viewport */
         
        }
        .hero-text h1
        {
              font-size: 22px;
              
        }
        
        .hero-text p
        {
            font-size: 0.9rem;
            color: #fff;
            padding: 0rem 0 2rem 0;
        }
        
      
          .hero-text p::after
        {
          content: "";
          height: 5px;
          background-color: #fff;
          display: block;
          width: 430px;
          margin-left: -1px;
        }
      
        .show
        {
           
        text-decoration: none;
        background-color: #063970;
        padding: 1rem 2rem ;
        border-radius: 6px;
        font-size: 2.1rem;
        font-weight: bold;
        letter-spacing: 2px;
        color: #fff;
        margin-left: 45px;
        margin-top: 500px;
        }
      
      
 
  }
  