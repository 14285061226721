.TripService
{
    margin: 2rem 6rem;
    color: #3a2a2a;
}

.TripServiceh1
{
    font-size: 3rem;
}

.tripcardss
{
    margin-top: 22rem;
    display: flex;
    justify-content: space-between;
}

.t-card
{
    width: 100%;
    height: 550px;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
    margin-top: -300px;
    

}

.t-image
{
    height: 500px;
    overflow: hidden;
    border-radius: 7px;
}

.t-image img
{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.t-image:hover img
{
    transform: scale(1.3);
}

.t-card h4
{
    font-size: 1.08rem;
    padding: .9rem 0 .2rem 0;
}

@media screen and (max-width:650px)
{

.tripcardss
{
    flex-direction: column;
    margin-top: 50px;
}

.t-card
{
        margin-top: -10px;
        margin-top: 20px;
}

}

