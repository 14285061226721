
.image-service img
{
    width: 250px;
height: 350px;
object-fit: cover;
border-radius: 6px;
margin-top: 250px;
margin-right: 100px;
box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19)

}


.first-des-service
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 11.5rem ;
    margin-bottom: 150px;
}

.first-des-service h1
{   
    margin-bottom: 180px;
    margin-left: 400px;
}

.des-text-service
{
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
    margin-left: 50px;

}




.image-2 img
{
    width: 250px;
height: 350px;
object-fit: cover;
border-radius: 6px;
margin-top: 50px;
margin-right: 100px;
box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19)
}


.first-des-2
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 11.5rem ;
    margin-bottom: 150px;
}

.des-text-2
{
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
    margin-left: 50px;

}
